import React, { useEffect, Suspense, useContext, useState } from "react";
import URLConfig from "./URLConfig";
import Cookies from "js-cookie";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import { BrowserRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast, ToastContainer } from "react-toastify";

import { UPDATE_STATE, UserContext } from "./Context/UserContext";
import DeltaRoutes from "./Routes";
import TrackingService from "./TrackingService";

import "./css/anps.css";
import "./css/cp-search-page-responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomToast from "./Components/CustomToast";

const App = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBciL3ddLFh_TTqR-vfByxEwdiP8b1JBPM",
    authDomain: "cp-notification-fd216.firebaseapp.com",
    projectId: "cp-notification-fd216",
    storageBucket: "cp-notification-fd216.appspot.com",
    messagingSenderId: "457203151332",
    appId: "1:457203151332:web:f24a09fa5c1a36808517f1",
    measurementId: "G-DRYDSLBKH1",
  };

  const [, dispatch] = useContext(UserContext);
  const rolesState = useContext(UserContext);
  const [loadApp, setLoadApp] = useState(false);
  const trackingService = new TrackingService();

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
    const messaging = getMessaging();

    const setupNotifications = async () => {
      try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");
          // Get the FCM token
          const token = await getToken(messaging);
          console.log("token--",token);
          if(localStorage.getItem("mail")){
          var URL = URLConfig.SaveNotificationToken()          
          var response = await axios.post(URL,{
            EmailId : localStorage.getItem("mail"),
            NotificationToken : token,
            Username : localStorage.getItem("name"),
            EmployeeId:localStorage.getItem('empnumber')

          })
        }
        } else {
          console.log("Notification permission denied.");
        }
        // Handle foreground notifications
        onMessage(messaging, (payload) => {
          console.log("Foreground Message:", payload);
          toast(<CustomToast title={payload.data.title}
            body={payload.data.body} url={payload.data.url}/>, {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className:"bg-white text-dark",
            style:{width:"407px",right:"92px"}
            // transition: Bounce,
            });
          // Handle the notification or update your UI
          // setPayload((prevState) => [...prevState, { ...payload, show: true }]);
        });
      } catch (error) {
        console.error("Error setting up notifications:", error);
      }
    };
    setupNotifications();
  }, []);

  useEffect(() => {
    if (URLConfig.isOAuthLoginEnabled()) {
      console.log("session login 1");
      // user login
      processAfterLogin();
    } else {
      console.log("session login else");
    }
  }, []);

  useEffect(() => {
    // check session timeout for every 2 mins
    const newInterval = setInterval(() => {
      sessionTimeOut();
    }, 1000 * 120);
    return () => clearInterval(newInterval);
  }, []);


  const isPlannedOutage = (empNum) => {
    setLoadApp(true);
    return false;
  };

  const logoutCurrentUserLoginSession = () => {
    console.log("session login 14");
    Cookies.remove("name");
    Cookies.remove("country");
    Cookies.remove("empnumber");
    Cookies.remove("mail");
    Cookies.remove("UserLang");
    Cookies.remove("googtrans");
    Cookies.remove("LoggedInTime");
    Cookies.remove("isUserLoggedIn");

    localStorage.removeItem("name");
    localStorage.removeItem("country");
    localStorage.removeItem("empnumber");
    localStorage.removeItem("mail");
    localStorage.removeItem("UserLang");
    localStorage.removeItem("googtrans");
    localStorage.removeItem("LoggedInTime");
    localStorage.removeItem("isUserLoggedIn");
  };

  // check whether user session is within 24 hrs
  const sessionTimeOut = async () => {
    var epoch = Cookies.get("LoggedInTime");
    var epochLS = localStorage.getItem("LoggedInTime");

    var begin = epochLS != null ? parseInt(epochLS) : 0;
    // var epochDate = envData !== "PROD" ? begin + 1800 : begin + 86400;
    var epochDate = begin + 86400;
    const now = new Date();
    //const ew = Math.floor(now.getTime() / 1000);
    let getUserLoginTimeURL = URLConfig.getURL_GetUserLoginTime();
    let ew = await axios.get(getUserLoginTimeURL);
    ew = ew.data;
    console.log("session login time 2", ew);

    if (!(begin < ew && ew < epochDate)) {
      Cookies.remove("name");
      Cookies.remove("country");
      Cookies.remove("empnumber");
      Cookies.remove("mail");
      Cookies.remove("UserLang");
      Cookies.remove("googtrans");
      Cookies.remove("LoggedInTime");
      Cookies.remove("isUserLoggedIn");

      // remove user session from LS
      localStorage.removeItem("name");
      localStorage.removeItem("country");
      localStorage.removeItem("empnumber");
      localStorage.removeItem("mail");
      localStorage.removeItem("UserLang");
      localStorage.removeItem("googtrans");
      localStorage.removeItem("LoggedInTime");
      localStorage.removeItem("isUserLoggedIn");
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
      window.location.href = process.env.REACT_APP_AUTH_URL;
      return;
    }
  };

  const processUserLogin = () => {
    console.log("session login 12");
    logoutCurrentUserLoginSession();
    if (localStorage.getItem("userSessionId")) {
      console.log("session login 13");
      localStorage.removeItem("userSessionId");
    }

    //redirect for Auth
    window.location.href = process.env.REACT_APP_AUTH_URL;
  };

  const processAfterLogin = async () => {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("u") && localStorage.getItem("empnumber") === null) {
      console.log("session login 2");
      let userSessionId = uuidv4();
      if (localStorage.getItem("userSessionId")) {
        console.log("session login 19");
        localStorage.removeItem("userSessionId");
      }
      localStorage.setItem("userSessionId", userSessionId);
      // Detect User
      let userLoginDetails = urlParams.get("u");
      localStorage.setItem("UserDetailsBase64", userLoginDetails);
      var encoded = Buffer.from(urlParams.get("u"), "base64").toString();
      urlParams = new URLSearchParams(encoded);
      console.log("urlParams", urlParams.values());
      var empNumber = urlParams.get("empNumber");
      var isInternal = urlParams.get("isInternal");
      // debugger;
      if (isInternal === "True") {
        localStorage.setItem("isChannelPartner", 0);
      } else {
        localStorage.setItem("isChannelPartner", 1);
      }
      Cookies.set("empnumber", empNumber);
      localStorage.setItem("empnumber", empNumber);

      var LoggedInTime = urlParams.get("LoggedInTime");
      Cookies.set("LoggedInTime", LoggedInTime);
      localStorage.setItem("LoggedInTime", LoggedInTime);

      console.log("session login 22");
      window.location.href = window.location.origin;
    } else if (localStorage.getItem("empnumber") === null) {
      console.log("session login 23");
      processUserLogin();
    }

    // check emp id exists and valid
    if (localStorage.getItem("empnumber") !== null) {
      console.log("session login 24");
      if (!isPlannedOutage(localStorage.getItem("empnumber"))) {
        console.log("session login 25");
        if (!localStorage.getItem("userSessionId")) {
          console.log("session login 26");
          let userSessionId = uuidv4();
          localStorage.setItem("userSessionId", userSessionId);
        }
        // if (Cookies.get("isUserLoggedIn") === "1") {
        if (localStorage.getItem("isUserLoggedIn") == "1") {
          console.log("session login 27");
          sessionTimeOut();
        } else {
          console.log("session login 28");
          var epoch = Cookies.get("LoggedInTime");
          var epochLS = localStorage.getItem("LoggedInTime");
          var begin = epochLS != null ? parseInt(epochLS) : 0;
          var epochDate = begin + 120;
          const now = new Date();
          //const ew = Math.floor(now.getTime() / 1000);
          let getUserLoginTimeURL = URLConfig.getURL_GetUserLoginTime();
          let ew = await axios.get(getUserLoginTimeURL);
          ew = ew.data;
          console.log("session login time", ew);

          if (!(begin < ew && ew < epochDate)) {
            console.log("session login 29");
            Cookies.remove("name");
            Cookies.remove("country");
            Cookies.remove("empnumber");
            Cookies.remove("mail");
            Cookies.remove("UserLang");
            Cookies.remove("googtrans");
            Cookies.remove("LoggedInTime");
            Cookies.remove("isUserLoggedIn");

            localStorage.removeItem("name");
            localStorage.removeItem("country");
            localStorage.removeItem("empnumber");
            localStorage.removeItem("mail");
            localStorage.removeItem("UserLang");
            localStorage.removeItem("googtrans");
            localStorage.removeItem("LoggedInTime");
            localStorage.removeItem("isUserLoggedIn");
            window.location.href = process.env.REACT_APP_AUTH_URL;
            return;
          }
          Cookies.set("isUserLoggedIn", 1);
          localStorage.setItem("isUserLoggedIn", 1);
        }
        //getEmployeeDetails(Cookies.get("empnumber"));
        //getEmployeeDetails(localStorage.getItem("empnumber"));
        if (localStorage.getItem("isChannelPartner") === "1") {
          getChannelPartnerUserDetails(localStorage.getItem("empnumber"));
          console.log("ischannelpartner yesss");
        } else {
          getEmployeeDetails(localStorage.getItem("empnumber"));
          console.log("ischannelpartner nooooo");
        }

        if (urlParams.has("u")) {
          console.log("session login 31");
          window.location.href = window.location.origin;
        }
      }
    }
  };

  const getEmployeeDetails = async (empNumber) => {
    console.log("session login 33");
    const URL = URLConfig.getURL_EmployeeAPI().toString() + empNumber;
    try {
      const res = await axios.get(URL);
      if (res?.data) {
        const Name = res.data.name;
        const Country = res.data.country;
        const mail = res.data.email;
        Cookies.set("name", Name);
        Cookies.set("country", Country);
        Cookies.set("empnumber", empNumber);
        Cookies.set("mail", mail);
        Cookies.set("UserLang", res.data.languagePreference);
        Cookies.set("googtrans", "/en/" + res.data.languagePreference);
        Cookies.set("isUserActive", res.data.isActive);

        localStorage.setItem("name", Name);
        localStorage.setItem("country", Country);
        localStorage.setItem("empnumber", empNumber);
        localStorage.setItem("mail", mail);
        localStorage.setItem("UserLang", res.data.languagePreference);
        localStorage.setItem("googtrans", "/en/" + res.data.languagePreference);
        localStorage.setItem("isUserActive", res.data.isActive);
        //Set User Roles
        if (res.data.roles.length > 0) {
          Cookies.set("roles", res.data.roles.join(","));
          localStorage.setItem("roles", res.data.roles.join(","));
        } else {
          Cookies.set("roles", "Guest");
          localStorage.setItem("roles", "Guest");
        }
        var RoleNames = [];
        res.data.roles.forEach((element) => {
          RoleNames.push(
            res.data.allRoles.filter((x) => x.roleSolar === element)[0].role
          );
        });
        RoleNames.length >= 1
          ? Cookies.set("roleNames", RoleNames.join(","))
          : Cookies.set("roleNames", "Guest");
        RoleNames.length >= 1
          ? localStorage.setItem("roleNames", RoleNames.join(","))
          : localStorage.setItem("roleNames", "Guest");
        Cookies.set("doc_source", undefined);
        localStorage.setItem("doc_source", undefined);

        dispatch({
          type: UPDATE_STATE,
          // payload: {
          //   Name: Cookies.get("name"),
          //   Country: Cookies.get("country"),
          //   empNumber: Cookies.get("empnumber"),
          //   isLoggedIn: true,
          //   UserRoles: res.data.roles,
          //   AllRoles: res.data.allRoles,
          //   UserLang: res.data.languagePreference,
          // },
          payload: {
            Name: localStorage.getItem("name"),
            Country: localStorage.getItem("country"),
            empNumber: localStorage.getItem("empnumber"),
            isLoggedIn: true,
            UserRoles: res.data.roles,
            AllRoles: res.data.allRoles,
            UserLang: res.data.languagePreference,
          },
        });
        trackingService.LogUserLogin(empNumber);
      } else {
        console.log("No Employee Data Found!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChannelPartnerUserDetails = async (empNumber) => {
    console.log("session login 34");

    const URL = URLConfig.getURL_GetChannelPartnerUser().toString() + empNumber;
    try {
      const res = await axios.get(URL);
      if (res?.data) {
        const Name = res.data.name;
        const Country = res.data.country;
        const mail = res.data.email;
        Cookies.set("name", Name);
        Cookies.set("country", Country);
        Cookies.set("empnumber", empNumber);
        Cookies.set("mail", mail);
        Cookies.set("UserLang", res.data.languagePreference);
        Cookies.set("googtrans", "/en/" + res.data.languagePreference);
        Cookies.set("isUserActive", res.data.isActive ? 1 : 0);

        localStorage.setItem("name", Name);
        localStorage.setItem("country", Country);
        localStorage.setItem("empnumber", empNumber);
        localStorage.setItem("mail", mail);
        localStorage.setItem("UserLang", res.data.languagePreference);
        localStorage.setItem("googtrans", "/en/" + res.data.languagePreference);
        localStorage.setItem("isUserActive", res.data.isActive ? 1 : 0);

        if (res.data.roles.length > 0) {
          Cookies.set("roles", res.data.roles.join(","));
          localStorage.setItem("roles", res.data.roles.join(","));
        } else {
          Cookies.set("roles", "Guest");
          localStorage.setItem("roles", "Guest");
        }
        var RoleNames = [];
        res.data.roles.forEach((element) => {
          RoleNames.push(
            res.data.allRoles.filter((x) => x.roleSolar === element)[0]?.role
          );
        });
        RoleNames.length >= 1
          ? Cookies.set("roleNames", RoleNames.join(","))
          : Cookies.set("roleNames", "Guest");
        RoleNames.length >= 1
          ? localStorage.setItem("roleNames", RoleNames.join(","))
          : localStorage.setItem("roleNames", "Guest");
        //Set User Roles

        dispatch({
          type: UPDATE_STATE,
          // payload: {
          //   Name: Cookies.get("name"),
          //   Country: Cookies.get("country"),
          //   empNumber: Cookies.get("empnumber"),
          //   isLoggedIn: true,
          //   UserRoles: res.data.roles,
          //   AllRoles: res.data.allRoles,
          //   UserLang: res.data.languagePreference,
          // },
          payload: {
            Name: localStorage.getItem("name"),
            Country: localStorage.getItem("country"),
            empNumber: localStorage.getItem("empnumber"),
            isLoggedIn: true,
            UserRoles: res.data.roles,
            AllRoles: res.data.allRoles,
            UserLang: res.data.languagePreference,
          },
        });
        trackingService.LogUserLogin(empNumber);
      } else {
        console.log("No Employee Data Found!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App hide-scrollbar">
      <BrowserRouter>
        <div className="AppContainer">
          <Suspense
            fallback={
              <div className="d-flex flex-column align-items-center">
                <div className="p-2">
                  <Spinner animation="border" role="status">
                    {/* Commented on 15th may as getting "Loading" text along with spinner on top */}
                    {/* <span className="sr-only">Loading...</span> */}
                  </Spinner>
                </div>
              </div>
            }
          >
            <ToastContainer />
            {/* {loadApp &&  <DeltaRoutes roles={rolesState[0].UserRoles} />} */}
            <DeltaRoutes roles={rolesState[0].UserRoles} />
          </Suspense>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
